<template>
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.829188 1.78502L7.85653 7.90919L14.3753 1.65212"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>
